import * as React from "react";
import { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  DeviceProps,
  getIndexByObjectImei,
  getOverviewImeiList,
  getUrlParams,
  maybeParseJson,
  parseUpdateData,
  dataEndpointConfig,
  getDeviceData,
} from "../data";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import {
  SanitizerOverview,
  SanitizerDetails,
  SanitizerDeviceDetailProps,
  getSanitizerDetails,
} from "../components/ProjectComponents";
import { StandardTemplate } from "../components/Templates";
import {
  LoadingScreen,
  RedAnimation,
  Row,
  HeadlineContainer,
  Headline,
  Section,
} from "../components/GlobalComponents";

const client = new W3CWebSocket(`wss://nodered.vodafone.dev/ws/iot-data`);

const SmartSanitizerPage = () => {
  const [view, setView] = useState<string>("overview");
  const [currentDevice, setCurrentDevice] = useState<string | null>(null);
  const [devices, setDevices] = useState<DeviceProps[]>([]);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [sanitizerData, setSanitizerData] = useState<
    SanitizerDeviceDetailProps[]
  >([]);
  const [loading, setLoading] = useState(true);

  /**
   * Websocket Connection for live data
   */
  useEffect(() => {
    client.onclose = () => {
      alert("Backend connection lost.");
    };
    client.onmessage = (message) => {
      const data = maybeParseJson(message.data);
      if (data.topic && data.topic === "iotData") {
        updateData(data);
      }
    };
  }, [view, sanitizerData, devices]);

  /**
   * Initial Data from the DB
   */
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      setLoading(true);

      const query =
        dataEndpointConfig.latestDeviceData.fields +
        `?type=eq.sanitizer` +
        dataEndpointConfig.latestDeviceData.order;

      getDeviceData(instance, account, query).then((data) => {
        const imeiList = getOverviewImeiList(data);
        getDetailedSanitizerDeviceData(imeiList, account, data);
        setDevices(data);
        subscribeToNewData(account, imeiList);
      });
    }
  }, [account, inProgress, instance]);

  const getDetailedSanitizerDeviceData = (
    imeiList: string[],
    account: AccountInfo,
    devices: DeviceProps[]
  ) => {
    const devicesData: Promise<unknown>[] = imeiList.map((imei: string) => {
      return new Promise((resolve) => {
        const query =
          dataEndpointConfig.data.fields +
          `&imei=eq.${imei}` +
          dataEndpointConfig.latestDeviceData.order;
        getDeviceData(instance, account, query).then((deviceData) => {
          const sanitizerDetails = getSanitizerDetails(deviceData, devices);
          resolve(sanitizerDetails);
        });
      });
    });

    Promise.all(devicesData).then((value) => {
      setSanitizerData(value as SanitizerDeviceDetailProps[]);
      setLoading(false);
    });
  };

  const updateData = (update: any) => {
    const newSanitizerData = [...sanitizerData];
    const deviceIndex = getIndexByObjectImei(
      newSanitizerData,
      update.imei.toString()
    );
    if (deviceIndex === -1) {
      return;
    }

    const parsedUpdate: DeviceProps = parseUpdateData(update);

    newSanitizerData[deviceIndex].data = [
      parsedUpdate,
      ...newSanitizerData[deviceIndex].data,
    ];

    newSanitizerData[deviceIndex] = getSanitizerDetails(
      newSanitizerData[deviceIndex].data,
      devices
    );
    setSanitizerData(newSanitizerData);
  };

  /**
   * Subscribe to new Data
   */
  const subscribeToNewData = (account: AccountInfo, imeiList: string[]) => {
    client.send(
      JSON.stringify({
        uuid: account.localAccountId,
        imeis: imeiList,
        topic: "subscribe",
      })
    );
  };

  /**
   * Switch between views
   */
  const changeView = (id?: string) => {
    setView(id ? "detail" : "overview");
    if (id) {
      setCurrentDevice(id);
    }
  };

  React.useEffect(() => {
    const params = getUrlParams();
    // IMEI set? => Go to detail view
    if (params && params.id) {
      changeView(params.id);
    }
  }, []);

  return (
    <StandardTemplate>
      <Section>
        {view === "overview" && (
          <>
            <HeadlineContainer color={"monochrome500"}>
              <Headline as={"h2"} styleAs={"h1"} text={"Smart Sanitizer"} />
            </HeadlineContainer>
            <Row center={"xs"}>
              {sanitizerData &&
                sanitizerData.map((device: SanitizerDeviceDetailProps) => (
                  <SanitizerOverview
                    key={device.imei}
                    device={device}
                    changeView={() => changeView(device.imei)}
                  />
                ))}
            </Row>
          </>
        )}
        {view === "detail" && (
          <SanitizerDetails
            deviceDetail={
              sanitizerData[
                getIndexByObjectImei(sanitizerData, currentDevice ?? "")
              ]
            }
            changeView={() => changeView()}
          />
        )}
      </Section>
      <LoadingScreen show={loading} animation={RedAnimation} />
    </StandardTemplate>
  );
};

export default SmartSanitizerPage;
